import React from 'react'
import InvoiceListing from '../components/InvoiceListing'

export default function Invoices() {
   
  return (
    <div className="flex flex-col gap-4 w-full">
        <InvoiceListing />
    </div>
    )
  }
